


















































































































































































































































































































import Vue from 'vue';
import { PowerStorage } from '@/powerStorage';
import moment from 'moment';
import { eventBus } from '@/bus';
import { getTimes } from 'suncalc';
import axios from 'axios';
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import { relationships } from '@/relationships';
import { CalendarEvent, CalendarRule } from '@/entities/calendar';
import { ActionItem } from '@/entities/ppv';
import { itemsManager } from '@/items';

export default Vue.extend({
  name: 'Calendar',
  components: {},
  props: {
    date: {},
    type: {},
    hideHeader: {},
    firstTime: {
      default: '6:00',
    },
    lastTime: {
      default: '24:00',
    },
    dragOutsideObject: {},
  },
  data: () => ({
    workHoursStart: '9:00',
    workHoursEnd: '17:00',
    clickedEvent: null as any,
    showMenu: false,
    showCreateMenu: false,
    menuPosX: 0,
    menuPosY: 0,
    storageEntity: PowerStorage.create<CalendarEvent>('calendar-events'),
    storageEntityRules: PowerStorage.create<CalendarRule>('calendar-rules'),
    storageEntityCalendars: PowerStorage.create('calendar-calendars'),
    storageEntityActionItems: PowerStorage.create<ActionItem>('action-items'),
    defaultCalendar: undefined as undefined | ProxiedDatasetItem,
    ready: false,
    value: '',
    events: [] as any,
    types: ['month', 'week', 'day', '4day'],
    colors: [
      '#2196F3',
      '#3F51B5',
      '#673AB7',
      '#00BCD4',
      '#4CAF50',
      '#FF9800',
      '#757575',
    ],
    swatches: [
      ['#800000', '#E50000', '#FF4081', '#FF7FAB', '#F900EA'],
      ['#EA80FC', '#BF55EC', '#9B59B6', '#7C4DFF', '#0231E8'],
      ['#81B1FF', '#3397DD', '#3082B7', '#04A9F4', '#02BCD4'],
      ['#1BBC9C', '#2ECD6F', '#F9D900', '#AF7E2E', '#FF7800'],
      ['#E65100', '#B5BCC2', '#667684', '#00000000'],
    ],
    dragEvent: null as any,
    dragStart: null as any,
    dragTime: null as any,
    createEvent: null as any,
    newEvent: null as any,
    createStart: null as any,
    extendOriginal: null as any,
    precipitationRanges: [] as any,
    dailyForecast: {} as any,
  }),
  async created() {
    await this.storageEntity.refresh();
    await this.storageEntityRules.refresh();
    await this.storageEntityCalendars.refresh();

    this.defaultCalendar = this.storageEntityCalendars.items.find(
      x => x.name.toLowerCase() == 'default'
    );
    await this.getPrecipitation();

    this.dailyForecast = await this.getDailyForecast();
    console.log('dailyForecast', this.dailyForecast);
  },
  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  methods: {
    async getDailyForecast() {
      const forecast = await axios.get(
        'https://api.open-meteo.com/v1/forecast?latitude=50&longitude=20&daily=weather_code,temperature_2m_max,temperature_2m_min,apparent_temperature_max,apparent_temperature_min&timezone=Europe%2FBerlin&forecast_days=16&past_days=7'
      );

      const weatherIcons = {
        0: 'weather-sunny',
        1: 'weather-partly-cloudy',
        2: 'weather-partly-cloudy',
        3: 'weather-partly-cloudy',
        45: 'weather-fog',
        48: 'weather-fog',
        51: 'weather-partly-rainy',
        53: 'weather-partly-rainy',
        55: 'weather-partly-rainy',
        56: 'weather-partly-snowy',
        57: 'weather-partly-snowy',
        61: 'weather-rainy',
        63: 'weather-rainy',
        65: 'weather-rainy',
        66: 'weather-snowy-rainy',
        67: 'weather-snowy-rainy',
        71: 'weather-snowy',
        73: 'weather-snowy',
        75: 'weather-snowy',
        77: 'weather-snowy',
        80: 'weather-pouring',
        81: 'weather-pouring',
        82: 'weather-pouring',
        85: 'weather-snowy-heavy',
        86: 'weather-snowy-heavy',
        95: 'weather-lightning-rainy',
        96: 'weather-hail',
        99: 'weather-hail',
      } as any;

      const dailyForecast: any = {};

      for (let i = 0; i < forecast.data.daily.time.length; i++) {
        const key = forecast.data.daily.time[i];
        dailyForecast[key] = {
          weatherCode: forecast.data.daily.weather_code[i],
          weatherIcon:
            'mdi-' + weatherIcons[forecast.data.daily.weather_code[i]],
          temperatureMax: forecast.data.daily.temperature_2m_max[i],
          temperatureMin: forecast.data.daily.temperature_2m_min[i],
          apparentTemperatureMax: forecast.data.daily.temperature_2m_max[i]
            .toFixed(0)
            .replace('-0', '0'),
          apparentTemperatureMin: forecast.data.daily.temperature_2m_min[i]
            .toFixed(0)
            .replace('-0', '0'),
        };
      }

      return dailyForecast;
    },
    async getPrecipitation() {
      const precipitation = await axios.get(
        'https://api.open-meteo.com/v1/forecast?latitude=50&longitude=20&hourly=precipitation_probability,precipitation&forecast_days=14'
      );

      console.log('precipitation', precipitation);

      let currentRange = null;
      for (let i = 0; i < precipitation.data.hourly.time.length; i++) {
        if (
          precipitation.data.hourly.precipitation[i] > 0 &&
          !precipitation.data.hourly.time[i].endsWith('00:00')
        ) {
          if (currentRange == null) {
            currentRange = {
              startTime: precipitation.data.hourly.time[i],
              endTime: '',
            };
          }
          currentRange.endTime = precipitation.data.hourly.time[i];
        } else {
          if (currentRange != null) {
            this.precipitationRanges.push(currentRange);
            currentRange = null;
          }
        }
      }

      if (currentRange != null) {
        this.precipitationRanges.push(currentRange);
        currentRange = null;
      }

      console.table(this.precipitationRanges);
    },
    intervalFormatter(locale: any) {
      return locale.time;
    },
    startDrag({ event, timed }: any) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    async startTime(tms: any) {
      // ignore right clicks on time
      if (tms.nativeEvent.button == 2) {
        return;
      }

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);

        // if(!this.createEvent) {
        this.createEvent = {
          name: '',
          color: '#00000000',
          calendar: this.defaultCalendar ? [this.defaultCalendar] : '',
          start: this.createStart,
          end: this.createStart,
          allDay: false,
          busy: true,
          item: null as ProxiedDatasetItem | null,
          justCreated: true,
        };

        this.events.push(this.createEvent);
        // }

        this.showCreateMenu = false;
      }
    },
    extendBottom(event: any) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms: any) {
      const mouse = this.toTime(tms);

      if (!this.dragEvent && this.dragOutsideObject) {
        // when mouse enters from outside of the component
        console.log(tms);
        this.dragEvent = {
          name: (this.dragOutsideObject as any).name,
          color: '#00000000',
          calendar: this.defaultCalendar ? [this.defaultCalendar] : '',
          start: mouse,
          end: mouse + 60 * 30 * 1000,
          allDay: false,
          busy: true,
          item: null as ProxiedDatasetItem | null,
          justCreated: true,
        };
        this.dragTime = null;
        this.extendOriginal = null;
        this.events.push(this.dragEvent);
        this.startTime(tms);
      }

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    async endDrag(e: any) {
      if (
        (this.createEvent && this.createEvent.justCreated) ||
        this.dragOutsideObject
      ) {
        console.log('end of drag for create new event');

        this.menuPosX = e.nativeEvent.clientX;
        this.menuPosY = e.nativeEvent.clientY;

        if (this.dragOutsideObject) {
          // dragging external sourced object
          this.newEvent = this.dragEvent;
          this.newEvent.template = this.dragOutsideObject;
        } else {
          // just dragging existing object
          this.newEvent = this.createEvent;
        }

        console.log('this.dragOutsideObject', this.dragOutsideObject);

        // hack to show menu, direct change is not working for some reason...
        setTimeout(() => {
          this.showCreateMenu = true;
        }, 10);
      }

      if (
        this.createEvent &&
        !this.createEvent.justCreated &&
        this.createEvent.item
      ) {
        console.log('end of drag for change duration');

        this.createEvent.item.startTime = moment(
          this.createEvent.start
        ).toISOString();
        this.createEvent.item.endTime = moment(
          this.createEvent.end
        ).toISOString();
      }

      if (this.dragEvent && this.dragEvent.item) {
        console.log('end of dragging event');

        this.dragEvent.item.startTime = moment(
          this.dragEvent.start
        ).toISOString();
        this.dragEvent.item.endTime = moment(this.dragEvent.end).toISOString();

        if (this.dragEvent.item.externalId) {
          this.dragEvent.item.externalTimeModified = true;
        }
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    async createNewEvent() {
      console.log('createNewEvent', this.newEvent);

      this.newEvent.item = await this.storageEntity.create({
        name: this.newEvent.name,
        startTime: moment(this.newEvent.start).toISOString(),
        endTime: moment(this.newEvent.end).toISOString(),
        allDay: this.newEvent.allDay === true,
        busy: this.newEvent.busy === true,
        color: this.newEvent.color.replace('#00000000', ''),
        // calendar: this.newEvent.calendar,
      });

      if (
        this.newEvent.template &&
        this.newEvent.template._dataset == 'action-items'
      ) {
        await relationships.updateRelationships(
          this.newEvent.item,
          this.storageEntity.getFieldByName('actionItem'),
          [this.newEvent.template],
          this.storageEntityActionItems
        );
      }

      await relationships.updateRelationships(
        this.newEvent.item,
        this.storageEntity.getFieldByName('calendar'),
        this.newEvent.calendar,
        this.storageEntity
      );

      this.newEvent = null;
      this.showCreateMenu = false;
    },
    cancelCreatingEvent() {
      this.showCreateMenu = false;
      if (!this.newEvent) {
        return;
      }
      console.log('cancelling create event', this.newEvent);
      this.events = this.events.filter((x: any) => x != this.newEvent);
      this.newEvent = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      if (this.dragEvent) {
        const i = this.events.indexOf(this.dragEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time: any, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms: any) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    getEventName(event: any) {
      return event.input.item?.name || event.input.name;
    },
    getEventTimed(event: any) {
      return !event.allDay;
    },
    getCalendarColorForEvent(event: any, fallbackColor = '') {
      if (!event.calendar && !event.item?.calendar) {
        return fallbackColor;
      }

      const calendarIds = (event.calendar || event.item.calendar || []).map(
        (y: any) => y._id || y.id
      );

      // console.log("calendarIds", calendarIds);

      if (calendarIds.length == 0) {
        return fallbackColor;
      }

      // const calendar = await relationships.getRelatedItem(event.item, "calendar");
      const calendars = this.storageEntityCalendars.getByIds(calendarIds);

      if (calendars.length == 0) {
        return fallbackColor;
      }

      // console.log("getCalendarColorForEvents", event, calendarIds, calendars, calendars[0].color);

      return calendars[0].color || fallbackColor;
    },
    getEventColor(event: any) {
      const calendarColor = this.getCalendarColorForEvent(event);

      let color = calendarColor || 'primary';

      for (const rule of this.storageEntityRules.items) {
        if (
          rule.regex &&
          event.item &&
          event.item.name &&
          event.item.name.match(new RegExp(rule.regex, 'i'))
        ) {
          if (rule.color) {
            color = rule.color;
          }

          if (rule.busyAction == 'setToBusy') {
            event.item.busy = true;
          }

          if (rule.busyAction == 'setToFree') {
            event.item.busy = false;
          }

          if (rule.deleteOnMatch) {
            console.log('deleting event as the rule matched');
            this.deleteEvent(event);
          }

          break;
        }
      }

      return event.item?.color || color;
    },
    async getEvents({ start, end }: any) {
      this.$emit('titleChanged', (this.$refs.calendar as any).title);
      this.$emit('periodChanged', { start, end });

      const events = [];

      await this.storageEntity.refresh();

      for (const item of this.storageEntity.items.filter(
        x => !x.externalDelete
      )) {
        events.push({
          name: item.name,
          start: moment(item.startTime).unix() * 1000,
          end: moment(item.endTime).unix() * 1000,
          allDay: item.allDay,
          item,
        });
      }

      // console.table(events);

      // const min = new Date(`${start.date}T00:00:00`).getTime();
      // const max = new Date(`${end.date}T23:59:59`).getTime();
      // const days = (max - min) / 86400000;
      // const eventCount = this.rnd(days, days + 20);
      //
      // for (let i = 0; i < eventCount; i++) {
      //   const timed = this.rnd(0, 3) !== 0;
      //   const firstTimestamp = this.rnd(min, max);
      //   const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
      //   const start = firstTimestamp - (firstTimestamp % 900000);
      //   const end = start + secondTimestamp;
      //
      //   events.push({
      //     name: this.rndElement(this.names),
      //     color: this.rndElement(this.colors),
      //     start,
      //     end,
      //     timed,
      //   });
      // }

      this.events = events;
    },
    rnd(a: any, b: any) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr: any) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    showEventMenu(e: any) {
      console.log('event being passed', e.event);
      console.log('mouse event', e.nativeEvent);
      e.nativeEvent.preventDefault();
      this.showMenu = false;
      this.menuPosX = e.nativeEvent.clientX;
      this.menuPosY = e.nativeEvent.clientY;
      // make event usable by menuClick items
      this.clickedEvent = e.event;
      console.log('clickedEvent', this.clickedEvent);
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async toggleFreeBusy() {
      if (!this.clickedEvent) {
        return;
      }

      this.clickedEvent.item.busy = !this.clickedEvent.item.busy;
    },
    setColor(colorObj: any) {
      if (!this.clickedEvent) {
        return;
      }
      let color = colorObj.hexa;
      if (color == '#00000000') {
        color = '';
      }
      console.log('setting color to', color);
      this.clickedEvent.color = color;
      this.clickedEvent.item.color = color;
    },
    async duplicateEvent() {
      if (!this.clickedEvent) {
        return;
      }
      console.log('duplicating', this.clickedEvent);

      const newEvent = {
        ...this.clickedEvent,
      };

      newEvent.item = await this.storageEntity.create({
        ...this.clickedEvent.item,
        _id: undefined,
      });

      console.log(newEvent);

      this.events.push(newEvent);
    },
    async deleteEvent(event: any) {
      if (!event) {
        return;
      }

      console.log('deleting', event);

      // TODO add confirmation dialog

      if (event.item.externalId) {
        event.item.externalDelete = true;
      } else {
        await itemsManager.remove(event.item);
        // await this.storageEntity.remove(event.item);
      }

      this.events = this.events.filter(
        (e: any) => e.item._id != event.item._id
      );
    },
    openItemForEvent() {
      if (!this.clickedEvent) {
        return;
      }
      eventBus.$emit('openItemDialog', this.clickedEvent.item);
    },
    eventClicked(e: any) {
      console.log('event clicked', e, e.event.item);
      // eventBus.$emit("openItemDialog", e.event.item);
    },
    today() {
      this.value = '';
    },
    copyToClipboard(text: string) {
      navigator.clipboard.writeText(text);
    },
  },
  computed: {
    intervalCount(): any {
      return moment(this.lastTime, 'HH:mm').diff(
        moment(this.firstTime, 'HH:mm'),
        'hours'
      );
    },
    getTimes(): any {
      return (date: string) => {
        return getTimes(moment(date, 'YYYY-MM-DD').toDate(), 50, 20); // TODO get from current location
      };
    },
    getSunset(): any {
      return (date: string) => {
        return moment(this.getTimes(date).sunset).format('HH:mm');
      };
    },
    getSunrise(): any {
      return (date: string) => {
        return moment(this.getTimes(date).sunrise).format('HH:mm');
      };
    },
    cal(): any {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY(): any {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },
    eventItems(): any {
      // for nicer syntax for watching items
      return this.storageEntity.items;
    },
  },
  watch: {
    dragOutsideObject(val, old) {
      console.log('object changed', old, val);

      if (old == null && val != null) {
        this.cancelDrag();
        this.cancelCreatingEvent();
      }
    },
    eventItems(val) {
      this.getEvents({});
    },
    showCreateMenu(val) {
      if (!val) {
        this.cancelCreatingEvent();
      }
    },
  },
});
