



























































































































import {PowerStorage} from "@/powerStorage";
import Vue from "vue";
import FieldEditor from "@/components/FieldEditor.vue";
import {itemsManager} from "../items";
import ChecklistEditor from "@/components/ChecklistEditor.vue";
import ContentEditor from "@/components/ContentEditor.vue";
import { templatesManager } from '@/templates';
import SourceEditorDialog from '@/components/SourceEditorDialog.vue';
import { addTimeslot } from '@/timeslots';

export default Vue.extend({
  name: "ItemDialog",
  components: {
    FieldEditor,
    ContentEditor,
    ChecklistEditor,
    SourceEditorDialog,
  },
  props: ["item"],
  data() {
    return {
      storageEntity: null as null | PowerStorage,
      templatesEntity: PowerStorage.create("templates"),
      itemsManager,
      forceRefresh: 0,
      sourceDialog: false,
    };
  },
  async created() {
    this.storageEntity = PowerStorage.create(this.item._dataset);

    await this.storageEntity.refresh();
    await this.templatesEntity.refresh();
  },
  computed: {
    showAddTimeslot(): boolean {
      // this is ugly hack to show only for action items...
      return this.storageEntity?.dataset == "action-items";
    },
  },
  methods: {
    addTimeslot() {
      addTimeslot(this.item);
    },
    close() {
      this.$emit('close');
    },
    minimize() {
      this.$emit("minimize");
    },
    async remove() {
      await itemsManager.remove(this.item);
      this.close();
    },
    async saveAsTemplate() {
      await templatesManager.saveAsTemplate(this.item);
    },
    async applyTemplate(t: any) {
      await templatesManager.applyTemplate(t, this.item);
      this.forceRefresh++;
    },
  },
});
