























































import { ProxiedDatasetItem } from "@/sync/ProxiedDataset";
import Vue from "vue";
import { eventBus } from "../bus";
import { ItemsManager } from '@/items';
import { addTimeslot } from '@/timeslots';

export default Vue.extend({
  name: "NameEditor",
  components: {},
  props: [
    "readonly",
    "type",
    "placeholder",
    "value",
    "default",
    "childrenItemsCounts",
    "item",
    "dataset",
    "singleItemView",
  ],
  data() {
    return {
      checklistLength: 0,
      checklistDone: 0,
    };
  },
  created() {
    const checklist = this.item.checklist || [];
    this.checklistLength = checklist.length;
    this.checklistDone = checklist.filter((x: any) => x.done).length;
  },
  computed: {
    hasRecurrenceField(): boolean {
      return ItemsManager.hasRecurrenceSet(this.item);
    },
    showAddTimeslot(): boolean {
      // this is ugly hack to show only for action items...
      return this.dataset == "action-items";
    },
  },
  methods: {
    addTimeslot() {
      addTimeslot(this.item);
    },
    openItemDialog(item: ProxiedDatasetItem) {
      eventBus.$emit("openItemDialog", item);
    },
    focus() {
      const r = this.$refs["text"] as any;
      if (r && r.focus) {
        r.focus();
      }
    },
  },
});
