var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticStyle:{"z-index":"100"},attrs:{"value":true,"width":"85%","scrollable":""},on:{"click:outside":_vm.minimize,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}}},[(_vm.item)?_c('v-card',{attrs:{"height":"85vh"}},[_c('v-card-title',{staticClass:"text-h5 grey lighten-2 pr-3"},[_vm._v(" "+_vm._s(_vm.item.name)+" "),_c('v-spacer'),(_vm.showAddTimeslot)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addTimeslot(_vm.item)}}},[_c('v-icon',[_vm._v("mdi-calendar-plus")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sourceDialog = true}}},[_c('v-icon',[_vm._v("mdi-code-json")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-import")])],1)]}}],null,false,1373682227)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/datasets/templates","exact":""}},[_c('v-list-item-title',[_vm._v("Manage")])],1),(_vm.templatesEntity.items.length > 0)?_c('v-divider'):_vm._e(),_vm._l((_vm.templatesEntity.items.filter(
                function (x) { return x.targetDataset == _vm.storageEntity.dataset; }
              )),function(t){return _c('v-list-item',{key:t._id,attrs:{"link":""},on:{"click":function($event){return _vm.applyTemplate(t)}}},[_c('v-list-item-title',[_vm._v(_vm._s(t.name))])],1)})],2)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.saveAsTemplate()}}},[_c('v-icon',[_vm._v("mdi-file-export")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.minimize()}}},[_c('v-icon',[_vm._v("mdi-window-minimize")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',{key:_vm.forceRefresh},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('content-editor',{attrs:{"item":_vm.item}})],1),_c('v-col',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('checklist-editor',{attrs:{"value":_vm.item.checklist},on:{"change":function($event){_vm.itemsManager.updateItem(
                        _vm.item,
                        _vm.storageEntity.fields.find(
                          function (f) { return f.name == 'checklist'; }
                        ),
                        $event
                      )}}})],1),_vm._l((_vm.storageEntity.fields.filter(
                    function (f) { return !['parent', 'expand', 'checklist'].includes(f.type) &&
                      f.name != 'content'; }
                  )),function(field){return _c('v-row',{key:field.name,staticClass:"pb-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-2",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(field.label || field.name)+" ")]),_c('v-col',{attrs:{"cols":"10"}},[_c('field-editor',{attrs:{"singleItemView":true,"field":field,"item":_vm.item,"fields":_vm.storageEntity.fields,"storageEntity":_vm.storageEntity},on:{"change":function($event){return _vm.itemsManager.updateItem(_vm.item, field, $event)}}})],1)],1)})],2)],1)],1)],1)],1)],1):_vm._e()],1),_c('source-editor-dialog',{attrs:{"show":_vm.sourceDialog,"item":_vm.item},on:{"closed":function($event){_vm.sourceDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }