import { builtin, dataset, datasetIcon, datasetLabel, defaultValue, format, hidden, label, options, relationship, type } from '@/decorators';
import { BaseItem } from '@/entities/base';
import { FieldOption, FieldRelationship, FieldType } from '@/entities/field';

@datasetLabel("Events")
@datasetIcon({ icon: "mdi-calendar-text" })
@dataset("calendar-events")
export class CalendarEvent extends BaseItem {
  @type("datetime")
  @label("Start time")
  @format({
    absoluteDates: true,
    disableColor: true,
  })
  startTime = ""

  @type("datetime")
  @label("End time")
  @format({
    absoluteDates: true,
    disableColor: true,
  })
  endTime = ""

  @label("All day")
  @type("checkbox")
  allDay = false;

  @type("color")
  @label("Color")
  color = ""

  @label("External ID")
  externalId = ""

  @type("checkbox")
  @label("External delete")
  @hidden
  externalDelete = false

  @type("checkbox")
  @label("External time modified")
  @hidden
  externalTimeModified = false

  @label("Busy")
  @type("checkbox")
  busy = false;

  @label("Calendar")
  @type(FieldType.Relationship)
  @format({
    disableChips: true,
  })
  @relationship(new FieldRelationship("calendar-calendars", "name", false, "events", "name", true))
  calendar: FieldRelationship | null = null

  @label("Meeting URL")
  @type("url")
  meetingUrl = ""

  @hidden
  @label("Action item")
  @type(FieldType.Relationship)
  @format({
    disableChips: true,
  })
  @relationship(new FieldRelationship("action-items", "name", false, "timeslots", "name", true))
  actionItem: FieldRelationship | null = null
}

@datasetLabel("Calendars")
@datasetIcon({ icon: "mdi-calendar-multiple" })
@dataset("calendar-calendars")
export class Calendar extends BaseItem {
  @type("color")
  @label("Color")
  color = ""

  @hidden
  @label("Events")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("calendar-events", "name", true, "calendar", "name", false))
  events: FieldRelationship | null = null
}

@datasetLabel("Calendar rules")
@datasetIcon({ icon: "mdi-calendar-check" })
@dataset("calendar-rules")
export class CalendarRule extends BaseItem {
  @label("Regex")
  @builtin
  regex = ""

  @label("Order")
  @type("number")
  order = 0

  @type("color")
  @label("Color")
  @builtin
  color = ""

  @type("dropdown")
  @label("Busy action")
  @defaultValue("noChange")
  @builtin
  @options([
    new FieldOption("No change", "noChange"),
    new FieldOption("Set to busy", "setToBusy"),
    new FieldOption("Set to free", "setToFree"),
  ])
  busyAction = ""

  @type("checkbox")
  @label("Delete on match")
  @builtin
  deleteOnMatch = false
}
