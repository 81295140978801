import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import moment from 'moment/moment';
import { relationships } from '@/relationships';
import { PowerStorage } from '@/powerStorage';
import { Calendar, CalendarEvent } from '@/entities/calendar';
import { ActionItem } from '@/entities/ppv';

export async function addTimeslot(item: ProxiedDatasetItem) {
  console.log("adding timeslot for item", item);

  const calendarStorageEntity = await PowerStorage.create<Calendar>("calendar-calendars").refresh();
  const calendarEventsStorageEntity = PowerStorage.create<CalendarEvent>("calendar-events");
  const actionItemsStorageEntity = PowerStorage.create<ActionItem>("action-items");

  const defaultCalendar = calendarStorageEntity.items.find(x => x.name.toLowerCase() == "default");

  console.log("default calendar", defaultCalendar);

  const newEvent = await calendarEventsStorageEntity.create({
    name: item.name,
    startTime: moment().toISOString(),
    endTime: moment().add(30, "minutes").toISOString(),
    allDay: false,
    busy: true,
  });

  await relationships.updateRelationships(newEvent, calendarEventsStorageEntity.getFieldByName("actionItem"), [item], actionItemsStorageEntity);
  await relationships.updateRelationships(newEvent, calendarEventsStorageEntity.getFieldByName("calendar"), [defaultCalendar], calendarStorageEntity);
}
